<!--
 * @Date: 2021-01-30 22:21:16
 * @LastEditors: frank
 * @LastEditTime: 2021-03-31 20:31:22
 * @FilePath: /shop_frontend/src/views/memberCard/card.vue
 * @Description: Description
-->
<template>
  <section class="card">
    <div class="flex_left">
      <!-- <a-button type="primary" @click="toggele"> 切换表格 </a-button> -->
    </div>
    <ul class="card__list">
      <li class="card__add_box" @click="toAddCard">
        <a-icon type="plus" />
        <div class="card__add">添加会员卡</div>
      </li>
      <li
        v-for="el in cardList"
        @click.stop="editeCard(el)"
        :key="el.id"
        class="card__add_box card__show"
        :style="{ backgroundImage: `url(${el.background_url})`,color: el.font_color }"
      >
        <span>{{ el.name }}</span>
        <span>{{ el.level }}</span>
        <ul class="btns">
          <li @click.stop="openCard(el)">持卡会员</li>
          <li @click.stop="openActivate(el)">激活审核</li>
        </ul>
        <span class="delete_icon" @click.stop="delCard(el)"
          ><a-icon type="close-circle"
        /></span>
      </li>
    </ul>
    <div style="text-align: right">
      <a-pagination
        :defaultPageSize="15"
        :showTotal="showTotal"
        :total="total"
        :showQuickJumper="true"
        @change="change"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: "card",
  data() {
    return {
      cardList: [],
      page: 1,
      total: 0,
    };
  },
  components: {},
  computed: {
    params() {
      return { page: this.page };
    },
  },
  watch: {},
  created() {},
  mounted() {
    this.initTable();
  },
  methods: {
    editeCard(obj) {
      console.log(obj, "edit");
      this.$router.push({
        name: "memberCardAdd",
        query: {
          id: obj.id,
        },
      });
    },
    delCard(obj) {
      let _this = this;
      this.$confirm({
        title: "是否删除该记录?",
        // content: 'Some descriptions',
        okText: "确认",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          _this.$axios.delete("/cards/" + obj.id + "/").then((res) => {
            _this.initTable();
          });
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    openCard(obj) {
      this.$router.push({
        name: "memberList",
        query: {
          info: JSON.stringify(obj),
        },
      });
    },
    openActivate(obj) {
      this.$router.push({
        name: "activateCard",
        query: {
          info: JSON.stringify(obj),
        },
      });
    },
    change(page) {
      this.page = page;
      this.initTable();
    },
    showTotal(total) {
      return `共 ${total} 条`;
    },
    toggele() {},
    toAddCard() {
      this.$router.push({
        name: "memberCardAdd",
      });
    },
    initTable() {
      this.$axios.get("/cards/", { params: this.params }).then((res) => {
        this.cardList = res.results || [];
        this.total = res.count || 0;
      });
    },
  },
};
</script>

<style scoped lang="less">
.flex_left {
  display: flex;
  flex-flow: row-reverse nowrap;
}
.card {
  &__add {
    opacity: 0.7;
  }
  &__add_box {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 100px;
    background: #ddd;
    border-radius: 10px;
    cursor: pointer;
    > i {
      font-size: 30px;
      color: #fff;
    }
    margin-right: 10px;
    margin-bottom: 10px;
  }
  &__show {
    background: #fff;
    color: #000;
    box-shadow: 0px 0px 0px 1px #ddd;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  &__list {
    display: flex;
    flex-flow: row wrap;
  }
}
.btns {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  color: #6892f7;
  > li {
    font-size: 10px;
    &:last-child {
      margin-left: 4px;
    }
  }
}
.card__show {
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
}
.delete_icon {
  position: absolute;
  right: 10px;
  top: 4px;
  color: red;
  font-size: 14px;
  font-weight: 600;
}
</style>
